@import url(https://fonts.googleapis.com/css2?family=Balsamiq+Sans&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --board: #352631;
  --body: #352631;
  --box: #bceedd;
  --box-shadow: rgb(0, 0, 0);
  --box-hover: rgb(255, 235, 191);
  --button: #3F88C5;
  --button-hover: rgb(35, 97, 148);
  --cat: #3F88C5;
  --o: #f6ae2d;
  --x: #f24333;
}

.color-picker {
    color: rgb(236, 255, 62);
}

/*---------------GAME------------------*/
body {
    background-color: #352631;
    background-color: var(--body);
}

button {
    background-color: #07BEB8;
    border: none;
    border-radius: 1em;
    box-shadow: 0.25em 0.25em 1em rgb(0, 0, 0);
    box-shadow: 0.25em 0.25em 1em var(--box-shadow);
    font-family: 'Balsamiq Sans', cursive;
    height: 4em;
    width: 7em;
}

.board {
    background-color: #352631;
    background-color: var(--board);
    border-radius: 1em;
    box-shadow: 0.25em 0.25em 1em rgb(0, 0, 0);
    box-shadow: 0.25em 0.25em 1em var(--box-shadow);
    display: flex;
    flex-wrap: wrap;
    height: 40em;
    margin-left: 2em;
    width: 40em;
}

.box {
    align-items: center;
    background-color: #bceedd;
    background-color: var(--box);
    border-radius: 1em;
    display: flex;
    height: 3.55em;
    justify-content: center;
    margin: 0.25em;
    width: 3.55em;
}

.cat {
    color: #3F88C5;
    color: var(--cat);
}

.game {
    align-items: flex-start;
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 7em;
    padding-top: 1em;
    position: fixed;
    width: 75vw;
}

.o {
    color: #f6ae2d;
    color: var(--o);
}

.small-board {
    border-radius: 1em;
    display: flex;
    flex-wrap: wrap;
    height: 12.25em;
    margin: 0.5em;
    width: 12.25em;
}

.turn {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 20em;
    width: 10em;
}

.x {
    color: #f24333;
    color: var(--x);
}

#board-cat {
    height: 40em;
    width: 40em;
}

#board-o {
    height: 40em;
    width: 40em;
}

#board-x {
    height: 40em;
    width: 40em;
}

#box-cat {
    height: 3em;
    width: 3em;
}

#box-o {
    height: 3em;
    width: 3em;
}

#box-x {
    height: 3em;
    width: 3em;
}

#small-board-cat {
    height: 12em;
    width: 12em;
}

#small-board-o {
    height: 12em;
    width: 12em;
}

#small-board-x {
    height: 12em;
    width: 12em;
}

#turn-o {
    height: 10em;
    width: 10em;
}

#turn-x {
    height: 10em;
    width: 10em;
}

button:hover {
    background-color: rgb(35, 97, 148);
    background-color: var(--button-hover);
    cursor: pointer;
}

.hover:hover {
    cursor: pointer;
    background-color: rgb(255, 235, 191);
    background-color: var(--box-hover);
}

.turn > p {
    font-family: 'Balsamiq Sans', cursive;
}

/*---------------GAME-------------------*/

/*--------------MEDIA QUERIES------------*/

@media only screen and (max-width: 1103px) {
    .board {
        height: 30em;
        width: 30em;
    }

    .box {
        height: 2.5em;
        width: 2.5em;
    }

    .game {
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
    }

    .small-board {
        height: 9em;
        width: 9em;
    }

    #board-cat, #board-o, #board-x {
        height: 30em;
        width: 30em;
    }

    #box-cat, #box-o, #box-x {
        height: 2.5em;
        width: 2.5em;
    }

    #small-board-cat, #small-board-o, #small-board-x{
        height: 8.75em;
        width: 8.75em;
    }
}

@media only screen and (max-width: 895px) {

    .board {
        margin-left: 0em;
    }

    .game {
        left: 0em;
        width: 100vw;
    }

    .turn {
        height: 5em;
        margin-right: 2em;
        width: 5em;
    }

    #turn-o {
        height: 5em;
        width: 5em;
    }

    #turn-x {
        height: 5em;
        width: 5em;
    }
}

@media only screen and (max-width: 591px) {
    .board {
        height: 25em;
        width: 25em;
    }

    .box {
        height: 1.9em;
        width: 1.9em;
    }

    .small-board {
        height: 7.25em;
        width: 7.25em;
    }

    #board-cat, #board-o, #board-x {
        height: 25em;
        width: 25em;
    }

    #box-cat, #box-o, #box-x {
        height: 1.9em;
        width: 1.9em;
    }

    #small-board-cat, #small-board-o, #small-board-x{
        height: 7em;
        width: 7em;
    }
}

@media only screen and (max-width: 507px) {
    .board {
        height: 30em;
        width: 30em;
    }

    .box {
        height: 2.5em;
        width: 2.5em;
    }

    .game {
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
    }

    .small-board {
        height: 9em;
        width: 9em;
    }

    .turn {
        flex-direction: row;
        justify-content: space-around;
        margin-bottom: 2em;
        width: 15em;
    }

    #board-cat, #board-o, #board-x {
        height: 30em;
        width: 30em;
    }

    #box-cat, #box-o, #box-x {
        height: 2.5em;
        width: 2.5em;
    }

    #small-board-cat, #small-board-o, #small-board-x{
        height: 8.75em;
        width: 8.75em;
    }
}

@media only screen and (max-width: 454px) {
    .board {
        height: 25em;
        width: 25em;
    }

    .box {
        height: 1.9em;
        width: 1.9em;
    }

    .small-board {
        height: 7.25em;
        width: 7.25em;
    }

    #board-cat, #board-o, #board-x {
        height: 25em;
        width: 25em;
    }

    #box-cat, #box-o, #box-x {
        height: 1.9em;
        width: 1.9em;
    }

    #small-board-cat, #small-board-o, #small-board-x{
        height: 7em;
        width: 7em;
    }
}

@media only screen and (max-width: 454px) {
    .board {
        height: 20em;
        width: 20em;
    }

    .box {
        height: 1.35em;
        width: 1.35em;
    }

    .small-board {
        height: 5.65em;
        width: 5.65em;
    }

    #board-cat, #board-o, #board-x {
        height: 20em;
        width: 20em;
    }

    #box-cat, #box-o, #box-x {
        height: 1.35em;
        width: 1.35em;
    }

    #small-board-cat, #small-board-o, #small-board-x{
        height: 5.65em;
        width: 5.65em;
    }
}
